import React from 'react';

import { useScrollToId } from '~/hooks/use-scroll-to-id';
import SmartLawnPlanSection from '~/components/static/home/smart-lawn-plan-section/smart-lawn-plan-section';
import { HomepageTrendingItems } from '~/components/sunday-store/components/product-details/trending-products';
import NeighborsSection from '~/components/static/home/neighbors-section/neighbors-section';
import YellowFlagSection from '~/components/static/home/yellow-flags-section/yellow-flag-section';
import ReviewsSection from '~/components/static/home/reviews-section/reviews-section';
import AddressEntrySection from '~/components/static/home/address-entry-section/address-entry-section';
import PromoBanner from '~/components/static/promo-banner/promo-banner';
import { usePromoBanner } from '~/hooks/use-promo-banner';
import { homeRoute } from '~/routes';
import { useSEO } from '~/hooks/use-seo';
import { useVwoExperiment } from '~/hooks/use-vwo-experiment';
import { TRENDING_ITEMS_TEST } from '~/utils/vwo-tests';
import { AutocompleteTypes } from '~/components/inputs/autocomplete-address-input/autocomplete-storage';
import { ZIPCODE_FUNNEL_FLAG } from '~/feature-flags';
import { useIpZipCode } from '~/hooks/use-ip-zip-code';

export const HomePage = () => {
  useSEO({
    title: 'Sunday Lawn Care',
    description:
      "Sunday takes the guesswork out of growing your dream backyard. With our custom lawn and pest plans, plus personalized plant recommendations, it's easier than ever to create an outdoor space your family will love.",
    canonical: homeRoute(),
  });
  useScrollToId();

  const { address, addressText } = useIpZipCode();

  const { promoBanner, isLoading } = usePromoBanner(homeRoute());

  const { variant: trendingItemsVariant } = useVwoExperiment(
    TRENDING_ITEMS_TEST.NAME
  );

  const showTrendingItemsVariant =
    trendingItemsVariant === TRENDING_ITEMS_TEST.SHOW_TRENDING_VARIANT;

  return (
    <>
      <HeroSection address={address} addressText={addressText} />
      <PromoBanner
        content={promoBanner}
        isLoadingContent={isLoading}
        className="inverse-margin"
      />
      <SmartLawnPlanSection address={address} addressText={addressText} />
      {showTrendingItemsVariant && <HomepageTrendingItems />}
      <NeighborsSection />
      <YellowFlagSection />
      <ReviewsSection />
    </>
  );
};

const HeroSection = ({ address, addressText }) => {
  if (ZIPCODE_FUNNEL_FLAG) {
    return (
      <AddressEntrySection
        type={AutocompleteTypes.ZIP_CODE}
        initialValues={{ address, addressText }}
      />
    );
  }

  return <AddressEntrySection />;
};

export default HomePage;
